:root {
    --main_color: #b3e5fc;
    --text-color: #ffffff
}

html {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    height: 100%;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}

#app {
    display: flex;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    min-height: 100%;
}

.header, .footer {
    max-width: 450px;
}

.header {
    background-color: #6CC8C3;
    position: fixed;
    width: 100%;
    height: 65px;
    display: flex;
    padding: 20px 45px;
}

.logo {
    cursor: pointer;
    width: 100px;
}

.body {
    margin: 65px 0;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer {
    width: 100%;
    height: 65px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6CC8C3;
    color: #fff;
    padding: 20px 45px;
    font-size: 16px;
}

/*  --- performance --- */

.performance-card {
    background-color: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    padding: 15px;
    cursor: pointer;
}

.performance-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15),
    0 3px 4px rgba(0, 0, 0, 0.25);
}

.performance-card img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 15px 15px 0 0;
}

.performance-card-info {
    display: flex;
    flex-direction: column;
    padding: 24px 0 12px 0;
}

.performance-card-title {
    font-size: 22px;
    font-weight: 900;
    color: #3D3D3D;
    line-height: 24px;
}

.performance-card-type {
    color: #29A39C;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.performance-card-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.performance-card-age {
    color: #252525;
    font-size: 10px;
}

.performance-card-button {
    background-color: #29A39C;
    color: #fff;
    font-weight: 900;
    font-size: 17px;
    text-align: center;
    padding: 8px 15px;
    border-radius: 10px;
}

.performance-details {
    padding-top: 24px;
}

.performance-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #252525;
}

.performance-details-info {
    font-size: 16px;
}

.performance-details-age {
    font-size: 14px;
}